import { Box, Container, Grid, Group, Image, rem, Stack, Text, Title } from '@mantine/core';

import MedalRibbonsStar from './MedalRibbonsStar.svg';
import PieChart from './PieChart.svg';
import ShieldCheck from './ShieldCheck.svg';
import classes from './Why.module.css';
import image from './WhyGoldframer.jpg';

export default function WhyGoldframer(props) {
  return (
    <Container {...props}>
      <Grid grow py={{ base: 50, md: 100 }} gutter={{ base: 50, md: 100 }} overflow="hidden">
        <Grid.Col span={{ md: 5 }} m={'0 auto'} visibleFrom={'md'}>
          <Image src={image} className={classes.image} />
        </Grid.Col>
        <Grid.Col span={{ md: 7 }}>
          <div>
            <Title className={classes.title} ta={{ base: 'center', xs: 'left' }} maw={{ base: '80%', xs: '100%' }} m={'0 auto'}>
              Why Goldframer
            </Title>
            <Text mt="md" className={classes.subtitle}>
              Goldframer empowers art lovers around the globe to participate in a market that harmonizes with their values. Our
              platform facilitates fractional ownership of exquisite Islamic artworks through a general partnership method,
              allowing you to hold a piece of history and partake in the art’s potential financial appreciation.
            </Text>

            <Grid mt={'xl'}>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap={'nowrap'} align={'stretch'} justify={'flex-start'}>
                  <Box pt={5}>
                    <Image src={PieChart} w={48} />
                  </Box>
                  <Stack>
                    <Text c="gfdark.9" fz={rem(20)}>
                      Fractional
                      <br />
                      Ownership
                    </Text>
                    <Text c="gfdark.5" fz={rem(16)}>
                      Participate without the need for substantial capital. Small or large, your contribution matters.
                    </Text>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap={'nowrap'} align={'stretch'}>
                  <Box pt={5}>
                    <Image src={ShieldCheck} w={48} />
                  </Box>
                  <Stack>
                    <Text c="gfdark.9" fz={rem(20)}>
                      Blockchain-Preserved
                      <br />
                      Ownership
                    </Text>
                    <Text c="gfdark.5" fz={rem(16)}>
                      Each artwork's ownership is securely and transparently recorded on the blockchain, ensuring clear and
                      tamper-proof records.
                    </Text>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap={'nowrap'} align={'stretch'} mt={'sm'}>
                  <Box pt={5}>
                    <Image src={MedalRibbonsStar} w={48} />
                  </Box>
                  <Stack>
                    <Text c="gfdark.9" fz={rem(20)} className="tw-balance">
                      Rigorous Due
                      <br />
                      Diligince of Artworks
                    </Text>
                    <Text c="gfdark.5" fz={rem(16)}>
                      We work with the top experts in the Islamic Arts fields to carefully select the artworks that have
                      appreciation potential.
                    </Text>
                  </Stack>
                </Group>
              </Grid.Col>
            </Grid>
            <Box m={'0 auto'} maw={250} hiddenFrom={'md'}>
              <Image src={image} className={classes.image} />
            </Box>
          </div>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
